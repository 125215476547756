@mixin trans-quick(){ transition:.18s ease all;}
.trans-quick{@include trans-quick();}; 
@mixin trans-medium(){ transition:.4s ease all;};
.trans-medium{ @include trans-medium();}
@mixin trans-slow(){ transition:.7s ease all;};
.trans-medium{@include trans-slow();}

@mixin shrm-loading{
	$baseLoaderSize:60px;
	position:relative;
	//.trans-medium; 
	&:before, &:after{
		content:"";position:absolute; 
		opacity:0; filter: alpha(opacity=0); 
		visibility:hidden;
		//transition: visibility 0s ease-out 0.2s, opacity .4s ease-out 0s, top .4s ease-out .4s;
		@include trans-medium; 
	}
	&:before{
		border-radius: 500rem;
		z-index:2;
		border: 5px solid;
		left: 50%; top: 50%; width: $baseLoaderSize; height: $baseLoaderSize;
		border-color: rgba(#C7C7C7, 70%/100.0%);
		border-bottom-color: var(--bs-primary, #656565);
		margin-left: -$baseLoaderSize / 2;
		//transform: translateX(-50%) translateY(-50%);
		margin-top:-$baseLoaderSize / 2;
		box-shadow:0 0 30px #fff;
		animation: fa-spin .55s infinite linear;
		padding: 0 !important;
	}
	&:after{
		z-index:1;
		left:0; top:0; width:100%; height:100%;
		background-color: rgba(255,255,255,0.94);
		background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNgYAAAAAMAASsJTYQAAAAASUVORK5CYII=);
	}

/* ##Sizes


* .loading-sm
* .loading-lg
* .loading-xl


##Positioning	


 * Based on parent element
	* .loading-top-10{top: 10%
	* .loading-top-20{top: 20%
	* .loading-top-30{top: 30%
	* .loading-top-50{top: 50%
 * Based on screen size
	* .loading-scr-middle{top: 50vh
	* .loading-scr-top{top: 25vh
	 
*/
	&.loading-top-10{&:before{top: 10%}}
	&.loading-top-20{&:before{top: 20%}}
	&.loading-top-30{&:before{top: 30%}}
	&.loading-top-50{&:before{top: 50%;}}
	&.loading-scr-middle{&:before{top: 50vh}}
	&.loading-scr-top{&:before{top: 25vh}}
	
	&.loading-xs{
		$loading-xs-aspect:0.5;
		&:before{width: $baseLoaderSize * $loading-xs-aspect; height: $baseLoaderSize * $loading-xs-aspect; margin-left: -$baseLoaderSize * $loading-xs-aspect / 2 ; border-width:3px; margin-top: -$baseLoaderSize * $loading-xs-aspect / 2}
	}
	&.loading-sm{
		&:before{width: $baseLoaderSize * 0.65; height: $baseLoaderSize * 0.65; margin-left: -$baseLoaderSize * 0.65 / 2 ; border-width:4px}
	}
	&.loading-lg{&:before{width: $baseLoaderSize * 1.3; height: $baseLoaderSize * 1.3; margin-left: -$baseLoaderSize * 1.3 / 2; border-width:6px}}
	&.loading-xl{&:before{width: $baseLoaderSize * 1.5; height: $baseLoaderSize * 1.5; margin-left: -$baseLoaderSize * 1.5  / 2; border-width:7px}}
	&.in{
		&:before, &:after{ 
			opacity:1; filter: alpha(opacity=100); visibility:visible;
			transition: none !important;
		}		
	}	//	/in
	
}	//	/.loading
.shrm-loading{
    @include shrm-loading();
    min-height: 300px;
}

.preloader{min-height: 300px;}