//@import "../node_modules/bootstrap/scss/bootstrap.scss";
/* @import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/utilities"; */

$theme-colors: (
    "primary": #005695,
    "danger": #CA361B,
    "success": #1BA289,
    "warning": #fd7e14
);

$border-radius: var(--bs-border-radius, 0);

$enable-negative-margins: true;


@import "~bootstrap/scss/bootstrap";

@import "scss/loader";


:root{
  --bs-font-sans-serif: "proxima nova", system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  --bs-body-line-height: 1.3;
  --bs-border-radius: 0;
  font-size: var(--bs-body-font-size);
  @include media-breakpoint-down(md){
    --bs-body-font-size: 16px !important;
  }
}

input{ touch-action: none; }

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100%{
    opacity: 100%; 
  }
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.grid {
  display: grid;
  grid-template-rows: repeat(var(--bs-rows, 1), 1fr);
  grid-template-columns: repeat(var(--bs-columns, 12), 1fr);
  gap: var(--bs-gap, 1.5rem);
}
.result-list{
  background-color: var(--bs-body-bg, #fff);

  &.loading-false{
    animation-name: fade-in;
    animation-duration: .6s;
    animation-fill-mode: forwards;
  }
  .result-header{
    background: var(--bs-primary);
    color: #fff;    
    span{
      font-weight: 600;
    }
    *[data-key="delta"]{
      grid-column: span 2;
      white-space: nowrap;
      
    }
    @include media-breakpoint-down(md){
      justify-content: center;
      .label{
        font-size: 1.3em;
      }
    }
    @include media-breakpoint-up(md){
      .the-column{
        text-align: center;
      }
    }
  } //.result-header

  .data-item{
    &:not(:last-child){
      border-bottom: 1px solid var(--bs-gray-300, #e9ecef);
    }
    &:not(:first-child){
      @include media-breakpoint-up(md){
        > .the-column{
          display: flex;
          justify-content: center;
          align-items: center; 
        }  
      }
      
      
    }
  }
  .result-header, .data-item{
    --bs-columns: 9;
    --bs-gap: 1rem;
    --col-narrow: 40px;

    @extend .grid;
    grid-template-columns: 1fr var(--col-narrow) var(--col-narrow) var(--col-narrow) var(--col-narrow) var(--col-narrow) var(--col-narrow) 20px 1px;
    
    
    >*{
      padding: calc(var(--bs-gap) / 2) 0;
      &:first-child{
        padding-left: calc(var(--bs-gap) / 2);
      }
      &:first-child{
        padding-left: calc(var(--bs-gap) / 2);
        padding-right: calc(var(--bs-gap) / 2);
      }
      &:not(:first-child){
        //text-align: center;
      }
      @for $var from 1 to 5 {
        &[data-key=y#{$var}]{
          
        }
      }

      &.the-column{
        &.indicator{
          color: var(--bs-gray, currentColor);
          &[data-value="-2"]{color: var(--bs-danger, currentColor);}
          &[data-value="2"]{color: var(--bs-success, currentColor);}
          &[data-value="0"]{color: var(--bs-gray, currentColor);}
        }
        &.sparkline_data{
          svg{
            overflow: visible;
          }
        }
        &[data-key="delta"]{
          @include media-breakpoint-up(md){
            position: relative;
            &:before{
              content: "";
              position: absolute; left: -10px; top: 5px; 
              width: 1px; height: calc(100% - 10px);
              background-color: rgb(0 0 0 / 6%);
              box-shadow: 1px 0 1px rgb(255 255 255 / 20%);
            }
            &[data-value="- -"]{
              margin-right: -45px;
              ~.indicator{
                display: none;
              }
            }
          }
        }
        &[data-key^=y]{
          >div>span{
            &:empty{
              &:before{
                content: "- -";
                opacity: .6;
              }
            }
            white-space: nowrap;
          }
        }
      } // .the-column
    }

    @include media-breakpoint-down(md){
      
      &.result-header{
        display: flex;
        >*:not(.label){
          display: none;
        }
      }
      &.data-item{
        display: flex;
        flex-wrap: wrap;
          justify-content: space-between;
        >*{
          padding-left: calc(var(--bs-gap) / 2);
          padding-right: calc(var(--bs-gap) / 2);
        }
        .label{
          flex: 1 1 100%;          
          font-weight: 600;
          padding: calc(var(--bs-gap) / 2) calc(var(--bs-gap) / 2) 0;
          ~*{
            padding-top: 0;
            &.hr{
              display: block !important;
              flex: 1 1 100%;
              padding: 0
            }
          }

        }
        .the-column{
          >*{
            display: inline-block;
          }
          &:before{
            content: attr(data-label) ": ";
            white-space: nowrap;
            font-weight: 600;
            color: var(--bs-gray-500);            
          }
          &[data-key]{            
            &[data-key^="y"]{
              padding-bottom: 0;
              flex: 0 0 auto;            
            }
          }
          &.sparkline_data{display: none;}
          &.indicator{
            &::before{display: none;}
            //margin-right: auto;
          }

          &[data-key="delta"]{
            padding-top: 0;            
          }
        } //  .the-column
      }
    }
    
  } // .result-header, .data-item
}

.btn-export{ 
  &:not(.show){
    z-index: 0;
  }
  button{
    border: 0;
    font-weight: 600;
    position: relative;
    >*{
      position: relative; z-index: 1;
    }
    &:after{
      display: none;      
    }
    &:before{
      content: ""; position: absolute; z-index: 0; left: 0; top: 0; width: 100%; height: 100%;
      transition: .2s ease all;
      filter: brightness(80%);
    }
    .is-exporting-true &{
      @extend .disabled;
    }
    @each $color, $value in $theme-colors {
      &.btn-#{$color} {
        background-color: var(--bs-#{$color}) !important;
        border-color: var(--bs-#{$color}) !important; 
        &:hover, &:focus{
          background-color: var(--bs-#{$color}) !important;
          border-color: var(--bs-#{$color}) !important;
          &:before{
            background-color: inherit;            
          }
        }        
        &:focus{
          box-shadow: 0 0 0 0.25rem rgba(var(--bs-#{$color}-rgb), 0.4) !important;
        }
      }
      
    } 
  }
  

  .fa-chevron-down{
    transition: .6s ease all !important;
    .is-exporting-true &{
      display: none;
    }
    +svg{
      animation: spin 1s linear infinite;
      display: none;
      .is-exporting-true &{
        display: inline-block;
      }
    }
  }
  &.show{
    .fa-chevron-down{
      transform: rotate(180deg);
    }
  }

}

div.ddctrl__control{
  border-radius: var(--bs-border-radius) !important;
  min-height: 1rem !important;
  &--menu-is-open,
  &--is-focused {
     border-color: var(--bs-primary) !important;
     box-shadow: 0 0 0 0.25rem rgba(var(--bs-primary-rgb), 0.4) !important;
  } 
  .ddctrl__value-container,
  .ddctrl__input-container{
    padding-top:0;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
    
  }
  .ddctrl__dropdown-indicator{
    padding: 0 0.7rem;
  }
  .ddctrl__single-value,
  .ddctrl__placeholder{
    line-height: calc(#{$line-height-base} - 1px);
    padding-top: $form-select-padding-y ;
    padding-bottom: $form-select-padding-y ;
    
  }
  
}
.ddctrl__single-value,
.ddctrl__option{
    &:first-letter{
      text-transform: capitalize
    }
  } 
.ddctrl__option{
  white-space: normal;
  transition: .3s ease-out all;
  //word-break: break-all;
}

$drops : (
  "location"  : " Locations",
  "orgsizes"  : " Org Sizes",
  "industries": " Industries",
  "cats"      : " Categories",
  "majors"    : " Areas"
);

@each $key, $val in $drops {
  .select-#{$key}{
    &[data-value="all"]{
      .ddctrl__single-value{        
        &:after{
          content: $val 
        }
      }
    }
    .ddctrl__option{  
      position: relative;    
      > span{
        &[data-value="all"]{
          &:after{
            content: $val 
          }
          &::before{
            content: "";
            position: absolute; width: 100%; height: 1px; bottom:0; left: 0;
            background-color: #eee;
          }
        }
      }
    } 

  }
}

.is-exporting-true{
  pointer-events: none;
  .App{
    .export-title{
      opacity: 1;
    }
  }
}

.export-title{
  opacity: 0;
  &:not(.loading-false){
    color: var(--bs-body-bg, #fff);
    position: absolute;
  }
  .the-title{
    @extend .h3;
    line-height: 1.4;
    &>span{
      &:not(:empty):not(:first-child){
        &:before{
          content: " | ";
          opacity: .2;
          font-weight: 600;
        }
        &[data-label="all"]{
          text-transform: capitalize;
          &:after{
            content: attr(data-append);
          }
        }
      }
      &:last-child{
      }
    }
  }

  &.loading-false{
  }
}


/* .ddctrl__single-value{

  &:after{
    .select-location &{
      content: " Locations";
    }
    .select-orgsizes &{
      content: " Org Sizes";
    }
    .select-industries &{
      content: " Industries";
    }
    .select-cats &{
      content: " Categories";
    }
  }
} */


.licence-agr{
  @include media-breakpoint-down(sm){
    padding-bottom: 5rem !important;
  } 
  .App:not(.exporting) &{
    display: none;
  }
  >*{
    font-size: 0.875em;
  }
  p{
    margin-bottom: 0.875em;
  }
}
